.EnterForm__text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 7px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  color: #a0a0a0;
  padding: 0;
  margin: 0;
  text-align: center;
  grid-row: 4;
}

@media (min-width: 768px)
{
  .EnterForm__text {
    font-size: 14px;
    line-height: 17px;
  }
}
