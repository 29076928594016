.Header__nav {
  display: none;
}

@media (min-width: 1280px) {
  .Header__nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
