.About__row_font_light {
  font-family: Arial, Helvetica, sans-serif;
  color: #A0A0A0;
  vertical-align: bottom;
  height: 22px;
}

@media (min-width: 768px) {
  .About__row_font_light {
    height: 31px;
  }
}
