.Card__img {
  height: 168px;
  width: 100%;
}

@media (min-width: 768px)
{
  .Card__img {
    height: 190px;
  }
}

@media (min-width: 1280px)
{
  .Card__img {
    height: 203px;
  }
}
