.App__link_size_adaptive {
  font-size: 12px;
  line-height: 15px;
}

@media (min-width: 768px) {
  .App__link_size_adaptive {
    font-size: 14px;
    line-height: 17px;
  }
}