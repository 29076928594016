.Footer__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 30px 0;
  margin-block-end: auto;
}

@media (min-width: 768px) {
  .Footer__list {
    flex-direction: row;
    padding: 20px 0;
  }
}
