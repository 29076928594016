.Header {
  box-sizing: border-box;
  width: 100%;
  min-height: 74px;
  padding: 0 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

@media (min-width: 768px) {
  .Header {
    padding: 0 30px;
  }
}

@media (min-width: 1280px) {
  .Header {
    padding: 0 70px;
  }
}
