.Footer {
  font-family: 'Inter', Arial, sans-serif;
  min-height: 270px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@media (min-width: 768px) {
  .Footer {
    min-height: 172px;
  }
}

@media (min-width: 1280px) {
  .Footer {
    background: #FAFAFA;
  }
}
