.Hero__list-item {
  list-style: none;
  margin: 0;
  padding: 0 0 17px 0;
}

@media (min-width: 768px) {
  .Hero__list-item {
    padding: 0 0 30px 0;
  }
}