.EnterForm__button {
  width: 100%;
  height: 45px;
  background-color: #ff6838;
  color: white;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  border-radius: 3px;
  grid-row: 2;
}

@media (min-width: 768px)
{
  .EnterForm__button {
    font-size: 14px;
    line-height: 17px;
  }
}
