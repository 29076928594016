.Popup__confirm-text {
  max-width: 244px;
  font-size: 20px;
  line-height: 24.2px;
  color: #000;
  text-align: center;
  font-weight: 900;
  padding-top: 40px;
  margin: 0;
  will-change: contents;
}

@media (min-width: 530px) {
  .Popup__confirm-text {
    min-width: 358px;
    font-size: 24px;
    line-height: 29.05px;
    padding-top: 32px;
  }
}
