.Search__form {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Search__form::after {
  content: "";
  position: absolute;
  left: 4px;
  bottom: 0;
  height: 1px;
  width: calc(100% - 8px);
  border-bottom: 1px solid #E8E8E8;
}

@media (min-width: 768px) {
  .Search__form {
    flex-direction: row;
    padding-bottom: 109px;
  }

  .Search__form::after {
    width: 100%;
    left: 0;
  }
}

@media (min-width: 1280px)
{
  .Search__form {
    padding-bottom: 69px;
  }
}
