.Profile__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.Profile__row:first-of-type {
  height: 30px;
  align-items: flex-start;
  border-bottom: 1px solid #e8e8e8;
}

.Profile__row:last-of-type {
  height: 30px;
  align-items: flex-end;
  border-top: 1px solid #e8e8e8;
}
