.Menu__close-btn {
  padding: 0;
  margin: 0;
  width: 32px;
  height: 32px;
  border: none;
  outline: none;
  background-color: #000;
  background: no-repeat center url(../../../images/menu_close_btn.svg);
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .Menu__close-btn {
    top: 22px;
    right: 22px;
  }
}
