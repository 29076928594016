.NotFound {
  box-sizing: border-box;
  height: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .NotFound {
    max-width: 400px;
  }
}
