.Card {
  width: 300px;
  display: grid;
  grid-template-columns: 14px 1fr 44px;
  grid-template-rows: 14px 5px 23px 27px 1fr;
  background-color: #fdfdfd;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.08);
  padding: 0;
  margin: 0;
}

@media (min-width: 768px)
{
  .Card {
    width: 339px;
  }
}

@media (min-width: 1280px)
{
  .Card {
    width: 364px;
  }
}
