.Tech__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px 70px;
  width: 230px;
  list-style: none;
  padding: 0;
  margin: 0;
}

@media (min-width: 768px) {
  .Tech__list {
    width: calc(100% - 20px);
    justify-content: center;
    gap: 50px;
  }
}