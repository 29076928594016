.EnterForm__main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  grid-row: 2;
}

@media (min-width: 768px)
{
  .EnterForm__main {
    grid-row: 3;
  }
}
