.Student__text {
  padding: 0 0 20px;
  grid-area: about;
}

.Student__text:last-of-type {
  padding: 0 0 40px;
}

@media (min-width: 1280px) {
  .Student__text {
    line-height: 22px;
  }
}
