.EnterForm__input-error {
  position: relative;
  top: -17px;
  display: none;
  font-size: 10px;
  line-height: 12px;
  color: #EE3465;
}

.EnterForm__input-error:last-of-type {
  top: 0;
  margin-bottom: 10px;
}
