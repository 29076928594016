.Landing__section {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 70px 18px;
}

@media (min-width: 768px) {
  .Landing__section {
    padding: 90px 50px;
  }
}

@media (min-width: 1280px) {
  .Landing__section {
    padding: 110px 70px;
  }
}
