.Header__list-item {
  list-style: none;
  padding: 0 14px 0 0;
  margin: 0;
}

.Header__list-item:last-of-type {
  padding: 0;
}

@media (min-width: 768px) {
  .Header__list-item {
    padding: 0 30px 0 0;
  }
}
