.EnterForm__input {
  width: 100%;
  height: 28px;
  border: 1px #e8e8e8;
  border-style: none none solid none;
  font-size: 14px;
  line-height: 17px;
  padding: 0 0 6px 0;
  margin-bottom: 24px;
  box-sizing: border-box;
  color: black;
}

.EnterForm__input:last-of-type {
  margin-bottom: 7px;
}
