.App {
  background-color: #fff;
  color: #000;
  font-family: 'Inter', Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 320px;
  max-width: 1280px;
  height: 100vh;
}
