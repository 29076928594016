@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(./inter-v3-latin_cyrillic-regular.woff2) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url(./inter-v3-latin_cyrillic-regular.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url(./inter-v3-latin_cyrillic-500.woff2) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url(./inter-v3-latin_cyrillic-500.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
