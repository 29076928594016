.EnterForm__form {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 45px 14px 15px;
  grid-template-columns: 1fr;
}

@media (min-width: 768px)
{
  .EnterForm__form {
    grid-template-rows: 1fr 45px 16px 17px;
  }
}
