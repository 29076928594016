.App__link_style_btn {
  display: inline-block;
  background-color: #2BE080;
  border-radius: 3px;
  text-align: center;
  padding: 5px 12px;
}

@media (min-width: 768px) {
  .App__link_style_btn {
    padding: 8px 20px;
  }
}
