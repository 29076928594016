.EnterForm__header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
  min-height: 253px;
  padding-top: 56px;
  width: 100%;
  box-sizing: border-box;
  grid-row: 1;
}

@media (min-width: 768px)
{
  .EnterForm__header {
    gap: 40px;
    min-height: 0;
    align-items: flex-start;
    grid-row: 2;
    padding-top: 0;
  }
}
