.About__col {
  padding: 0;
  width: 100px;
}

@media (min-width: 768px){
  .About__col {
    width: 140px;
  }
}

@media (min-width: 1280px){
  .About__col {
    width: 228px;
  }
}
