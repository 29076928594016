.Footer__text_position_bottom {
  padding: 0;
}


@media (min-width: 768px)
{
  .Footer__text_position_bottom {
    padding: 20px 0;
  }
}
