.Tech__tile {
  box-sizing: border-box;
  width: 84px;
  background-color: #E8E8E8;
  border-radius: 10px;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.04em;
  padding: 22px 0 20px;
  margin: 0;
}

@media (min-width: 1280px) {
  .Tech__tile {
    font-size: 14px;
    line-height: 17px;
    width: 90px;
    padding: 21px 0 22px;
  }
}