.NotFound__message {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  padding: 0;
  margin: 0;
}

@media (min-width: 768px) {
  .NotFound__message {
    font-size: 16px;
    line-height: 19px;
  }
}