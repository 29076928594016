.Search__button {
  position: absolute;
  top: 19px;
  right: 20px;
  box-sizing: border-box;
  width: 34px;
  height: 34px;
  background: no-repeat center url("../../../images/search_btn.svg");
  background-size: contain;
  border: none;
  cursor: pointer;
}

@media (min-width: 1280px)
{
  .Search__button {
    right: 30px;
  }
}
